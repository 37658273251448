@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat-SemiBold.ttf);
}

:root {
    --plyr-color-main: #ff3729;
    --plyr-font-family: Montserrat;
    --plyr-video-control-background-hover: none;
    --plyr-audio-control-background-hover: none;
    --safe-area-padding-left: max(10px, env(safe-area-inset-left, 25px));
    --safe-area-padding-right: max(10px, env(safe-area-inset-right, 25px));
    --user-interaction-padding: 7.5px;
    -webkit-tap-highlight-color: transparent;
}

feed-view .ephemeral {
    position: absolute;
    z-index: 99999999999;
    top: 50%;
    left: 50%;
    height: 85px;
    opacity: 0;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
}

feed-view heart-icon.ephemeral {
    fill: var(--plyr-color-main);
}

feed-view bookmark-icon.ephemeral {
    fill: white;
}

feed-view .ephemeral.rise {
    animation: rise 1.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes rise {
    0% {
        opacity: 0;
        filter: blur(40px);
        transform: translateX(-50%) translateY(100%) scale(.5);
    }

    50% {
        opacity: 1;
        filter: blur(0);
        transform: translateX(-50%) translateY(-45%) scale(1);
    }

    100% {
        opacity: 0;
        filter: blur(40px);
        transform: translateX(-50%) translateY(-100%) scale(.8);
    }
}

.plyr__video-embed {
    position: fixed;
}

.plyr__controls {
    padding-right: var(--safe-area-padding-right) !important;
    padding-left: var(--safe-area-padding-left) !important;
}

.plyr__control {
    padding: var(--user-interaction-padding) !important;
}

.plyr__poster {
    transition: opacity 100ms ease-out !important;
    background-size: contain;
    display: initial !important;
    opacity: 0 !important;
}

.plyr__control--overlaid {
    transition: opacity 50ms ease-out, background 50ms ease-out !important;
    display: initial !important;
    padding: 12.5px !important;
    box-shadow: 0 0 5px black;
    background: transparent !important;
}

video-player.unstarted .plyr--paused .plyr__poster,
.section.active video-player:not(.unavailable) .plyr--paused .plyr__poster {
    opacity: 1;
    cursor: pointer;
}

.section.active video-player:not(.unavailable) .plyr--paused .plyr__control--overlaid {
    opacity: 1;
    background: var(--plyr-color-main) !important;
}

video-player.unavailable .plyr__poster,
video-player.unavailable .plyr__control--overlaid {
    opacity: 0 !important;
}

video-player {
    display: grid;
    grid-template-rows: 60px 1fr 60px;
    grid-template-columns: calc(40px + var(--safe-area-padding-left)) 1fr calc(40px + var(--safe-area-padding-right));
    grid-gap: 10px;
    height: 100svh;
    height: 100%;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

video-player loading-animation {
    grid-row: 1 / 4;
    grid-column: 1 / 4;
    z-index: 10;
}

video-player .user-interactions {
    grid-row: 2 / 3;
    grid-column: 3 / 4;
    margin: auto;
    margin-left: 0;
    transition: all .4s ease-in-out;
    opacity: 1;
    z-index: 2;
}

video-player .user-interactions * {
    padding: var(--user-interaction-padding);
    justify-content: center;
    align-content: center;
    aspect-ratio: 1;
    width: 25px;
    display: flex;
    cursor: pointer;
    stroke: white;
    fill: transparent;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .85));
    transition: fill 100ms ease-out;
}

video-player[liked] .user-interactions heart-icon {
    fill: var(--plyr-color-main);
    stroke: none;
}

video-player[saved] .user-interactions bookmark-icon {
    fill: white;
}

video-player.hide-controls .user-interactions {
    opacity: 0;
    pointer-events: none;
    transform: translateX(100%);
}

video-player .plyr {
    grid-row: 1 / 4;
    grid-column: 1 / 4;
    z-index: 0;
}

video-player .title-bar {
    grid-row: 1 / 2;
    grid-column: 1 / 4;
    padding: var(--user-interaction-padding) var(--safe-area-padding-right) 0 var(--safe-area-padding-left);
    color: white;
    text-shadow: 0 0 2px rgba(0, 0, 0, .5);
    font-family: sans-serif;
    font-size: 18px;
    pointer-events: none;
    opacity: 1;
    z-index: 1;
}

video-player .title-bar .card {
    display: flex;
    flex-wrap: nowrap;
    height: 50px;
    max-width: 100%;
    width: fit-content;
    border-radius: 50px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px 0 black;
    transition: all .4s ease-in-out;
}

video-player .title-bar .card bd-logo {
    background-color: #fff;
    border-radius: 50%;
    aspect-ratio: 1;
    height: 50px;
}

video-player .title-bar .card .title-text {
    width: 100%;
    margin-left: 6.5px;
    margin-right: 15px;
    text-shadow: 0 0 2px rgba(0, 0, 0, .5);
    transition: opacity .4s ease-in-out, transform .4s ease-in-out;
    display: flex;
    flex-direction: column;
    line-height: 1.1;
    place-content: center;
    overflow: hidden;
    opacity: 1;
}

video-player .title-bar .card .title-text * {
    overflow: hidden;
    white-space: nowrap;
    word-wrap: normal;
    text-overflow: ellipsis;
}

video-player:not(.unstarted).hide-controls .title-bar .card {
    -webkit-backdrop-filter: blur(0);
    backdrop-filter: blur(0);
    background-color: transparent;
    box-shadow: none;
}

video-player:not(.unstarted).hide-controls .title-bar .card .title-text {
    opacity: 0;
    transform: translateY(-100%);
}

video-player.hide-controls .title-bar .card,
video-player.hide-controls .title-bar .card .title-text {
    transition-delay: 4s;
    transition-property: all;
}